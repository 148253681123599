import React, { useRef } from 'react';
import css from './SectionFeaturedListing.module.css';
import ProductCard from './ProductCard';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { IconSpinner, NamedLink } from '../../../components';
import classNames from 'classnames';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

// Skeleton loader component for product cards that exactly matches the ProductCard layout
const ProductCardSkeleton = () => (
  <div className={css.skeletonProduct} role="presentation" aria-hidden="true">
    <div>
      <div className={css.skeletonImageWrapper}></div>
      <div className={css.skeletonContainer}>
        <div className={css.skeletonTitle}></div>
      </div>
      <div className={css.skeletonGenderContainer}>
        <div className={css.skeletonGender}></div>
        <div className={css.skeletonRating}></div>
      </div>
      <div className={css.skeletonMap}></div>
      <div className={css.skeletonText}></div>
    </div>
    <div className={css.skeletonIconContainer}>
      <div className={css.skeletonIcon}></div>
    </div>
  </div>
);

function SectionFeaturedListing({ loading, error, products, intl }) {
  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -300,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 300,
        behavior: 'smooth'
      });
    }
  };

  if (error) {
    return (
      <div className={css.errorContainer}>
        <span className={css.error}>
          {intl.formatMessage({ id: 'LandingPage.SectionFeaturedListingserror' })}
        </span>
      </div>
    );
  }

  // Show skeleton loaders while loading
  if (loading) {
    return (
      <div className={css.sectionWrapper} aria-label="Loading testers" role="region">
        <div className={css.container}>
          {Array(4).fill().map((_, index) => (
            <ProductCardSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={css.sectionWrapper} aria-label="Available testers" role="region">
      <button 
        className={css.navButton} 
        onClick={scrollLeft}
        aria-label="Scroll left"
      >
        <IoIosArrowBack className={css.navIcon} />
      </button>
      <div className={css.container} ref={containerRef}>
        {products && products.map((p, index) => (
          <ProductCard
            id={p?.id?.uuid}
            key={p?.id?.uuid}
            metadata={p?.attributes?.metadata}
            title={p?.attributes?.title}
            description={p?.attributes?.description}
            publicData={p?.attributes?.publicData}
            images={p?.images}
            createdAt={p?.attributes?.createdAt}
            intl={intl}
            geolocation={p?.attributes?.geolocation}
            price={p?.attributes?.price}
            authorName={p?.author?.attributes?.profile?.displayName}
            customImage={p}
            profileMetadata={p?.author?.attributes?.profile?.metadata}
          />
        ))}
      </div>
      <button 
        className={css.navButton} 
        onClick={scrollRight}
        aria-label="Scroll right"
      >
        <IoIosArrowForward className={css.navIcon} />
      </button>
      {products?.length ? (
        <NamedLink 
          name="SearchPage" 
          className={css.heroButton}
          aria-label="View all testers"
        >
          <FormattedMessage id="SectionHero.browseButtonViewAll" />
        </NamedLink>
      ) : null}
    </div>
  );
}

export default injectIntl(SectionFeaturedListing);
