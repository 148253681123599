import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ExternalLink, ResponsiveImage } from '../../../components';
import { formatMoney } from '../../../util/currency';
import { createSlug } from '../../../util/urlHelpers';
import css from './ProductCard.module.css';
import { AiFillStar } from 'react-icons/ai';
import { AiFillInstagram } from 'react-icons/ai';
import { AiFillFacebook } from 'react-icons/ai';
import { BsSnapchat } from 'react-icons/bs';
import { RiLinkedinFill } from 'react-icons/ri';
import { AiOutlineTwitter } from 'react-icons/ai';
import classNames from 'classnames';
import testImage from './test.jpg';
import SectionMapMaybe from '../../ListingPage/SectionMapMaybe';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { DynamicMap } from '../../../components/Map/MapboxMap';
import CardMap from './CardMap';
import config from '../../../config';
import Decimal from 'decimal.js';
import NoIGLinkPopup from '../../NoIGLinkPopup/NoIGLinkPopup';
import { shouldUseGeolocation } from '../../../util/geolocation/config';
import useIpGeolocation from '../../../util/geolocation/useIpGeolocation';

const { LatLng, Money } = sdkTypes;

function ProductCard({
  title,
  id,
  description,
  price,
  images,
  publicData,
  metadata,
  customImage,
  intl,
  authorName,
  geolocation,
  profileMetadata,
}) {
  // State to manage popup visibility
  const [showPopup, setShowPopup] = useState(false);
  
  // Check if the listing belongs to a profile that should use geolocation
  const shouldUseGeo = useMemo(() => {
    return shouldUseGeolocation(authorName, id);
  }, [authorName, id]);

  // Use our custom hook for geolocation
  const { 
    isLoading,
    userLocation,
    usingUserLocation,
    error
  } = useIpGeolocation(shouldUseGeo);

  const stars = metadata?.avgRating;
  const instagram = profileMetadata?.instagram || null;
  const instagramLink = instagram ? 'https://instagram.com/' + String(instagram) : '';
  const avgRating = stars ? new Decimal(stars).div(100).toNumber() : null;

  // Function to toggle popup
  const togglePopup = () => setShowPopup(!showPopup);

  const history = useHistory();
  const { dob, gender, location: profileLocation, name } = publicData;
  const getTitle = title => (title?.length > 25 ? title.substring(0, 15) + '...' : title);
  const formattedPrice = formatMoney(intl, new Money(1000, config.currency));

  const dateOfBirth = moment(dob, 'YYYY-MM-DD');
  const age = dateOfBirth
    ? parseInt(moment.duration(moment().diff(dateOfBirth)).asYears(), 10)
    : null;

  const handleClick = () => {
    history.push({
      pathname: `/l/${createSlug(title)}/${id}`,
    });
  };

  // Create accessible card title for screen readers
  const cardTitle = `${authorName?.toLowerCase() || 'Tester'}, ${age || ''} ${gender?.toLowerCase() || ''}`;
  
  // Determine which location to display - use user location if available, otherwise fall back to profile location
  const displayLocation = usingUserLocation && userLocation?.address 
    ? userLocation.address 
    : shouldUseGeo ? 'USA' : profileLocation?.address;
  
  // Determine which geolocation to use for the map - use user location if available, otherwise fall back to USA or profile
  const mapGeolocation = useMemo(() => {
    if (usingUserLocation && userLocation?.coords) {
      const lat = parseFloat(userLocation.coords.latitude) || 0;
      const lng = parseFloat(userLocation.coords.longitude) || 0;
      // Create proper LatLng instance to avoid type errors
      return new LatLng(lat, lng);
    }
    return shouldUseGeo ? new LatLng(37.0902, -95.7129) : geolocation; // Use USA center if geo should be used
  }, [usingUserLocation, userLocation, geolocation, shouldUseGeo]);

  return (
    <div className={css.product} role="article" aria-label={cardTitle}>
      <div className={css.contentWrapper} onClick={handleClick}>
        <div className={classNames(css.productImageWrapper, 'relative')}>
          {images && images[0] ? (
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={`Profile photo of ${authorName}`}
              image={images[0]}
              variants={['default']}
              loading="eager"
              fetchpriority="high"
              decoding="sync"
            />
          ) : (
            <div className={css.imagePlaceholder} aria-label="No profile image available"></div>
          )}
        </div>
        <div className={css.container}>
          <div className={css.title}>
            {authorName?.toLowerCase()}, &nbsp;
            {age}
          </div>
        </div>
        <div className={css.genderContainer}>
          <p className={css.gender}>
            {gender?.toLowerCase()}
            <span aria-hidden="true">&#xb7;</span>
          </p>

          {avgRating && (
            <>
              <span className={css.age}> {avgRating}</span>
              <span aria-hidden="true">
                <AiFillStar className={css.star} />
              </span>
              <span className={css.srOnly}>stars rating</span>
            </>
          )}
        </div>

        <div className={css.map}>
          {shouldUseGeo && isLoading ? (
            <div className={css.mapContainer} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p>Loading location...</p>
            </div>
          ) : (
            <CardMap
              containerClassName={css.mapContainer}
              mapClassName={css.mapContainer}
              center={mapGeolocation}
              circle={true}
              aria-label={`Map showing location near ${displayLocation || 'tester location'}`}
            />
          )}
        </div>
        <p className={css.text} title={displayLocation}>
          Around <span className={css.address}>{getTitle(displayLocation)}</span>
        </p>
      </div>
      <div className={css.iconContainer}>
        {instagramLink ? (
          <ExternalLink 
            href={instagramLink}
            className={css.socialLink}
            aria-label="Instagram profile"
          >
            <AiFillInstagram className={css.icon} aria-hidden="true" />
          </ExternalLink>
        ) : null}
      </div>

      {showPopup && <NoIGLinkPopup closePopup={togglePopup} />}
    </div>
  );
}

export default ProductCard;
